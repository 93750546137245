<template>
  <argon-button
    v-if="showButton"
    variant="outline"
    color="primary"
    :loading="isLoading"
    @click.prevent="handlePrintAirWayBill"
    ><i class="fas fa-print" style="font-size: 18px"></i>&nbsp;&nbsp;Print
    Airway Bill</argon-button
  >
</template>

<script>
import axios from "axios";
import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "PrintAirWayBillBulkButton",
  components: {
    ArgonButton,
  },
  props: {
    data: { required: true, type: Object },
    rowBulkSelected: { required: true, type: Object },
  },
  data() {
    return {
      basePath: axios.defaults.baseURL,
      urlPDFBulkAirwayBill: "/api/v1/pdf/bulkairwaybill",
      isLoading: false,
      showButton: false,
      bulkcode: "",
    };
  },
  created() {
    this.checkShowButton();
  },
  methods: {
    checkShowButton() {
      this.bulkcode = this.rowBulkSelected.code;
      if (this.rowBulkSelected.status == "completed") {
        this.showButton = true;
      }
      if (this.rowBulkSelected.status == "created") {
        this.showButton = false;
      }
      if (this.rowBulkSelected.status == "incompleted") {
        let _showButton = false;
        for (let list in this.data) {
          if (
            this.data[list].status != "" &&
            this.data[list].status != "Error" &&
            this.data[list].status != "Order Not Found"
          ) {
            _showButton = true;
          }
        }
        this.showButton = _showButton;
      }
    },
    handlePrintAirWayBill() {
      let process_type = "from own pdf";
      //let process_type = "from own ninja";

      if (process_type == "from own pdf") {
        let url =
          this.basePath + this.urlPDFBulkAirwayBill + "/" + this.bulkcode;
        window.open(url);
      } else if (process_type == "from own ninja") {
        console.log("from own ninja");
      }
    },
  },
};
</script>

<style></style>
